body {
  overflow-x: hidden;
}

.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  margin: 0px;
}

.switch input {
  display: none;
}

.switch .slider.round {
  border-radius: 26px;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch input:checked+.slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

.switch .slider.round:before {
  border-radius: 50%;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch input:checked+.slider {
  background-color: #28a745;
}

.switch .checkbox label::after {
  width: auto;
}

.custom-control-input:checked~.custom-control-label::before,
.page-item.active .page-link {
  border-color: #ff9c00;
  background-color: #ff9c00;
}

.btn-primary {
  background: #ff9c00 !important;
  border-color: #ff9c00 !important;
}

.text-primary,
body a.text-primary:focus,
body a.text-primary:hover,
.text-solitud:hover,
.auth-form-group-custom .auti-custom-input-icon {
  color: #ff9c00 !important;
}

.apex-charts .apexcharts-bar-series path {
  fill: rgba(255, 156, 0, 0.85) !important;
}

.text-solitud {
  color: #808080;
}

.page-title-right2 {
  display: flex;
  align-items: center;
}

.btn-w {
  padding: 5px 20px;
  margin-left: 15px;
}


/* Outer */
.popup {
  z-index: +9999;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.7);
}

/* Inner */
.popup-inner {
  max-width: 500px;
  width: 90%;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  z-index: 99;
  background: #fff;
}


/* Close Button */
.popup-close {
  width: 30px;
  height: 30px;
  padding-top: 5px;
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 0px;
  transition: ease 0.25s all;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  border-radius: 1000px;
  background: rgba(0, 0, 0, 0.8);
  font-family: Arial, Sans-Serif;
  font-size: 20px;
  text-align: center;
  line-height: 100%;
  color: #fff !important;
}

.popup-close:hover {
  -webkit-transform: translate(50%, -50%) rotate(180deg);
  transform: translate(50%, -50%) rotate(180deg);
  background: rgba(0, 0, 0, 1);
  text-decoration: none;
}

.popup-inner .products-top-bar {
  float: left;
  width: 100%;
  border-bottom: 1px solid #EBEBEB;
  margin-bottom: 20px;
}

.popup-inner .products-bottom-bar {
  float: left;
  width: 100%;
}

.popup-inner .sar-input-field {
  margin-bottom: 20px;
}

.popup-inner .sar-input-field form {
  display: flex;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #c2c2c2;
  background-color: #ffffff;
  padding: 10px 0px;
}

.popup-inner .sar-input-field form .number {
  border: 0px;
  padding: 0 15px;
  border-right: 1px solid #c2c2c2;
  box-sizing: border-box;
  width: 100%;
  outline: none !important;
  color: #363636;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  font-family: 'Poppins', sans-serif;
}



.page-title-box .breadcrumb * {
  font-weight: 500 !important;
  font-family: Inter, sans-serif;
}

.description-text {
  white-space: initial;
  font-size: 12px;
  max-width: 300px;
  margin-bottom: 0px;
}

.img-circle {
  border-radius: 50%;
}

.profile-details {
  text-align: center;
}

.user-img {
  margin-bottom: 10px;
}

.select2-container {
  width: 100% !important;
}

.field-repeater .btn {
  float: right;
  margin-bottom: 10px;
  font-size: 10px;
  padding: 4px 7px;
  margin-top: 10px;
}

.field-repeater>div {
  float: left;
  width: 100%;
  clear: both;
}

.custom_file input {
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .94rem + 2px);
  margin-bottom: 0;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  outline: none;
}

.custom_file input::-webkit-file-upload-button {
  border: 0px !important;
  background: #ced4da !important;
  height: 37px !important;
  outline: none;
}

.datepicker,
.select2-container--open {
  z-index: +9999 !important;
}

.Scrollable-form {
  overflow-y: scroll;
  max-height: 85vh;
}

.popup-w {
  max-width: 700px;
}

.note-editable.card-block {
  max-height: 200px !important;
}

.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  display: inline-block;
  margin-bottom: .5rem;
  font-size: .9rem !important;
  line-height: 1.5;
  color: #505d69;
}

.dataTables_length label .custom-select {
  width: auto;
  display: inline-block;
}

.dataTables_filter {
  text-align: right;
}

.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}

.dataTables_filter label .form-control {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}

.table-responsive>.row {
  margin: 0px;
}

.table-responsive>.row>div[class^="col-"]:first-child {
  padding-left: 0;
}

.table-responsive>.row>div[class^="col-"]:last-child {
  padding-right: 0px;
}

.popup-inner .card-title {
  font-weight: 500;
  text-transform: initial;
}

.table-responsive .table.mb-0 {
  margin-bottom: 15px !important;
}

.table-responsive .table.mb-0 {
  margin-bottom: 15px !important;
}

.table-responsive .dataTables_info {
  padding-top: 0.85em;
  white-space: nowrap;
}

.table-responsive .paging_simple_numbers {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

.table-responsive .paging_simple_numbers .pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
}

.page-link:hover {
  color: #fcb92c;
}

.filter_boxs {
  display: flex;
  justify-content: flex-end;
}

.dataTables_filter_select {
  margin-right: 15px;
  margin-bottom: .5rem;
}

.dataTables_filter_select .filter_select {
  display: inline-block;
  width: auto;
  border: 1px solid #ced4da;
  outline: none;
}

.button_detail {
  text-align: end;
}

.description_chapter ul {
  padding-left: 20px;
}

.chapter_details_show .details_row>h6 {
  font-size: 16px;
}

.chapter_details_show .details_row p:last-child,
.assessment_row1 .details_row p:last-child,
.assessment_row1 .details_row:last-child {
  margin-bottom: 0px;
}

.chapter_details_show .details_row,
.assessment_row1 .details_row {
  margin-bottom: 1rem;
}

.draft_published {
  width: auto;
  float: right;
}

.draft_published .control-label {
  display: block;
  line-height: 15px;
}

.assessment_name {
  max-width: calc(100% - 50%);
}

.btn_success {
  color: #28a745 !important;
}

.b-radius-0 {
  border-radius: 0px;
}

.assessment_description {
  display: inline-block;
  width: 100%;
}

.question-row .title-box {
  margin: 0 0 10px 0;
  justify-content: space-between;
  display: flex;
}

.dataTables_length_2 {
  display: flex;
}

.question-page-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.question-row .custom-select {
  width: 85px;
}

.dataTables_length .action_btn {
  float: none;
}

/***** action-checkbox *****/
.checkbox-box {
  width: 20px;
  display: block;
  position: relative;
  cursor: pointer;
  margin-bottom: 0px;
}

.checkbox-box span {
  font-size: 0px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 25px;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  display: inline-block;
}

.checkbox-box span:after {
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid #c1c1c1;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
}

.checkbox-box input {
  position: absolute;
  width: 20px;
  left: 0px;
  opacity: 0;
  z-index: +9999;
  height: 28px;
  cursor: pointer;
}


.checkbox-box .checkbox-box input[type=checkbox]:checked+span:after {
  border-color: #ff9c00;
}

.checkbox-box span:before {
  content: "\F0E1E";
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0px;
  z-index: 9;
  margin: 0;
  opacity: 0;
  text-align: center;
  line-height: 25px;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: 16px;
  line-height: 20px;
  color: #28a745;
}

.checkbox-box input[type=checkbox]:checked+span:before {
  opacity: 1;
}

.checkbox-false span:before {
  content: "\F1398";
  color: #ff3d60 !important;
}

.action-checkbox {
  display: flex;

}

.add-answer {
  display: inline-block;
  width: 100%;
}

.add-answer .btn {
  float: right;
  margin-top: 10px;
  font-size: 10px;
  padding: 4px 7px;
}

.question-row .dataTables_length {
  display: flex;
  float: right;
  /* width:310px; */
  justify-content: flex-end;
}

.btn-text {
  display: block;
  color: #505d69;
}

.btn-text:hover {
  color: #ff9c00 !important;
}

.chapter-detail-edit .col-sm-12 {
  position: relative;
}

.chapter-detail-edit .button_detail {
  position: fixed;
  right: 45px;
  z-index: +99;
  top: 85px;
}

.chapter-detail-edit {
  position: relative;
}

.question-row .title-box .question-title {
  font-size: .9rem;
  float: left;
}

.question-box td.choices {
  width: 40px;
}

.question-box .table td,
.question-box .table th {
  padding: 7px;
}

.question-box .table td {
  vertical-align: middle;
}

.question-no {
  float: left;
  font-size: .9rem;
  color: #343a40;
  font-family: Inter, sans-serif;
}

.question-box #add-more {
  font-size: 10px;
  padding: 4px 7px;
}

.assessment_name .form-control {
  width: 50%;
}

.marks label {
  display: flex;
  align-items: center;
}

.marks input {
  margin-left: 10px;
  width: 80px;
}

.btn_style {
  background: none;
  border: 0px;
  padding: 0px;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none;
}

.dropdown-item:not([href]) {
  cursor: pointer;
  color: #212529 !IMPORTANT;
}

.question-box td.answer input.form-control {
  margin-left: 0px;
  display: inline-block;
}

.nav-tabs-custom .nav-item .nav-link.active {
  color: #fcb92c;
}

.nav-tabs-custom .nav-item .nav-link::after {
  background: #fcb92c;
}

.nav-tabs-custom .nav-item .nav-link {
  font-weight: 800;
}

.datepicker table tr td .active.disabled,
.datepicker table tr td.active,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active:hover,
.datepicker table tr td.selected,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected:hover,
.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today:hover {
  background: #fcb92c !important;
}

.datepicker table tr th.prev,
.datepicker table tr th.next {
  cursor: pointer;
}

.datepicker table tr td.day {
  cursor: pointer;
}

.chapter-assessment-details .question-row {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 2px solid #eff2f7;
}

.chapter-assessment-details .question-row:last-child {
  padding-bottom: 0px;
  border-bottom: 0px;
}

.q_title_input {
  width: 100%;
}

.question-row .title-box .question-title {
  width: calc(100% - 250px);
  display: flex;
}

/*****  start Alert Popup  *****/
.ion-overlay-wrapper {
  border-radius: 13px;
  overflow: hidden;
  display: flex;
  background: #f9f9f9;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 250px;
  display: inline-block;
  width: 250px;
  font-family: Poppins, sans-serif;
}

.sc-ion-alert-ios-s {
  height: 100%;
  width: 100%;
}

.alert-head.sc-ion-alert-ios {
  padding-top: 12px;
  padding-bottom: 7px;
  text-align: center;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
}

.alert-head.sc-ion-alert-ios h2 {
  margin-top: 8px;
  color: #000;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  padding: 0px;
  margin-bottom: 0px;
}

.alert-message.sc-ion-alert-ios {
  -webkit-padding-start: 16px;
  padding-inline-start: 16px;
  -webkit-padding-end: 16px;
  padding-inline-end: 16px;
  padding-top: 0;
  padding-bottom: 21px;
  color: #000;
  font-size: 12px;
  text-align: center;
  font-family: Poppins, sans-serif;
}

button.alert-button {
  outline: none;
  cursor: pointer;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0;
  min-width: 50%;
  height: 44px;
  border-top: 0.55px solid #0000003b;
  border-right: 0.55px solid #0000003b;
  background-color: transparent;
  color: #fcb92c;
  font-size: 17px;
  /* overflow: hidden; */
  border-bottom: 0px !important;
  border-left: 0;
  font-weight: bold;
}

.alert-button-group.sc-ion-alert-ios {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  margin-right: unset;
  -webkit-margin-end: -0.55px;
  margin-inline-end: -0.55px;
  margin-right: -0.55px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

button.alert-button+button.alert-button {
  border-right: 0px !important;
  border-left: 0px !important;
}

.sc-ion-alert-ios-s .sc-ion-alert-ios-backdrop {
  background: #00000054;
  position: absolute;
  left: 0px;
  top: 0px;
  display: block;
  height: 100%;
  width: 100%;
  z-index: 999;
}

.alert_popup .modal-content {
  background: none;
  border: 0px;
}

/*****  End Alert Popup  *****/

/***** pagination-template  *****/
pagination-template ul.ngx-pagination li {
  width: 34px;
  cursor: pointer !important;
  height: 34px;
  padding: 0px !important;
  text-align: center;
  line-height: 34px;
  border-radius: 60px !important;
  margin: 0 3px;
}

pagination-template ul.ngx-pagination li.pagination-next,
pagination-template ul li.pagination-previous span,
pagination-template ul li.pagination-next a,
pagination-template ul li.pagination-previous span,
pagination-template ul.ngx-pagination li.pagination-previous a {
  font-size: 0px !important;
}

.ngx-pagination .pagination-previous a:before,
.ngx-pagination .pagination-previous.disabled:before {
  content: "\F0141" !important;
  font-family: "Material Design Icons" !important;
}

pagination-template ul li.pagination-next a:after,
pagination-template ul.ngx-pagination li.pagination-previous a:before {
  font-size: .9rem;
}

pagination-template ul.ngx-pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
  padding: 0px;
  display: flex;
}

pagination-template ul.ngx-pagination li a {
  padding: 0px !important;
  display: inline-block;
  line-height: 25px;
}

pagination-template ul.ngx-pagination li a:after {
  line-height: 34px;
  margin: 0px !important;
}

pagination-template ul.ngx-pagination li a .show-for-sr {
  display: none;
}

pagination-template ul.ngx-pagination li.current {
  background-color: #ff9c00;
  cursor: default !important;
}

pagination-template ul.ngx-pagination li:hover {
  background-color: #ff9c00;
}

pagination-template ul.ngx-pagination li:hover a {
  background: no-repeat;
  color: #fff;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
  margin: 0px !important;
}

.ngx-pagination .pagination-next a:after,
.ngx-pagination .pagination-next.disabled:after {
  content: "\F0142" !important;
  font-family: "Material Design Icons" !important;
  font-size: 0.9rem;
}

.angular-editor-wrapper .angular-editor-textarea {
  height: 250px !important;
  outline: none !important;
}

/*****  End pagination  *****/
.apexcharts-toolbar {
  display: none !important;
}

.apexcharts-legend.position-bottom {
  display: none !important;
}

.question-row .title-box .question-title .question-no {
  width: 30px;
}

/*****  start Sidebar menu *****/
#sidebar-menu ul li a {
  text-transform: capitalize;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a {
  background: #293041;
  color: #d7e4ec;
}

.metismenu li.active a *,
.metismenu li:hover a * {
  color: #ffff !important;
}


/********* responsive *********/
@media (max-width: 992px) {
  .sidebar-enable .vertical-menu {
    display: block;
    width: 240px !important;
    left: 0px !important;
    transition: all 0.3s;
  }

  .vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a span {
    display: contents;
    padding-left: 0px;
  }

  div#layout-wrapper .vertical-menu {
    display: block;
    left: -300px;
    transition: all 0.3s;
    z-index: 999;
    position: fixed;
  }

  .vertical-collpsed .navbar-brand-box {
    width: 73px !important;
  }

  .navbar-brand-box {
    display: block !important;
  }

  .vertical-collpsed .footer {
    left: 0px !important;
  }

  .vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a {
    background: transparent !important;
    width: auto !important;
  }
}

/*****  End Sidebar menu  *****/


/********* radio-box *********/
.radio-box {
  cursor: pointer;
  width: 20px;
  position: relative;
  display: inline-block;
  margin-bottom: 0px;
  height: 20px;
}

.radio-box span {
  font-size: 0px;
}

.radio-box span:after {
  content: "";
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: 0px;
  top: 0px;
  background: #ffff;
  // border-radius: 60px;
  border: 1px solid #c1c1c1;
}

.radio-box span:before {
  content: "";
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 60px;
  z-index: 9;
  opacity: 0;
  font-family: "Material Design Icons";
  color: #28a745;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  font-weight: 300;
}

.radio-box input {
  position: absolute;
  width: 20px;
  left: 0px;
  opacity: 0;
  z-index: +9999;
  height: 20px;
  cursor: pointer;
}

.radio-box input[type=radio]:checked+span:before {
  opacity: 1;
}

.radio-box input[type=radio]:checked+span:after {
  border-color: #28a745;
}

.radio-box input[type=radio]:checked+span:before {
  content: "\F0E1E";
}

.answer_row .input-answer {
  width: calc(100% - 145px);
  float: left;
  margin-right: 15px;
}

.answer_row>div {
  float: left;
  width: 100%;
}

.answer_row .radio-box {
  float: left;
  margin-top: 4px;
}

/********* End radio css  *********/
.question-box.table-responsive .table.mb-0 {
  margin-bottom: 0 !important;
}

.chapter_button button.btn.btn-danger:first-child {
  margin-right: 10px;
}

.width-full {
  width: 100%;
}

/**** 14-01-2021 ****/
.border-radius-50 {
  border-radius: 50%;
}
.courses-bg {
  padding-bottom: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 100%;
}
.user_img {
  height: 35px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 35px;
  border-radius: 60px;
}
.chapter_button {
  display: inline-block;
  width: 100%;
}

.field-repeater {
  display: inline-block;
  width: 100%;
}
.field-repeater .row .btn {
  float: left;
  margin-left: 13px;
}

.field-repeater  .btn {
  float: left;
}
.user_input {
  width: 150px;
  margin: auto;
}
.user_input div {
  display: flex;
  margin-bottom: 10px;
}
.user_email .user_input {
  width: 200px;
}
.switch_profile {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
}

.switch_profile label {
  margin-bottom: 0px;
}
.custom_img_input {
  display: flex;
  align-items: center;
}

.custom_img_input input {
  width: calc(100% - 35px);
  margin-right: 15px;
}
.icon-plus {
  margin-left: 0px !important;
  background-color: #fcb92c;
  color: #fff !important;
  width: 20px;
  height: 20px;
  font-weight: 300 !important;
  display: inline-block;
  font-size: 10px;
  text-align: center;
  line-height: 20px;
  border-radius: 60px;
}



a:not([href]) {
  cursor: pointer !important;
}
/**** responsive ****/
@media (max-width:1280px) {
  .question-row .title-box .question-title .question-no {
    width: 40px;
  }
}

@media (max-width: 992px) {
  .custom_file {
    margin-bottom: 1rem !important;
  }

}

@media (max-width:769px) {
  .table-responsive .table thead {
    display: none;
  }

  .table-responsive .table tbody td {
    display: block;
    text-align: end;
    position: relative;
    padding-left: 0px;
    padding-right: 0px;
    min-height: 45px;
  }

  .table-responsive .table tbody td .user-icon {
    float: none;
  }

  .table-responsive .table tbody td:before {
    content: attr(data-title)":";
    position: absolute;
    left: 5px;
    color: #505d69;
    font-weight: 600;
    font-family: Nunito, sans-serif;
  }

  .table-responsive .table tbody td[data-title="Labels"] {
    height: 40px;
  }

  .table-responsive .table tbody td:first-child {
    border-top: 0px;
  }

  .description-td .description-text {
    margin-left: auto;
  }

  .table-responsive .table tbody td:last-child {
    border-bottom: 1px solid #c9cbce;
  }

  .table-responsive .table tbody tr:last-child td:last-child {
    border-bottom: 0px;
  }

  .dataTables_length label,
  .dataTables_filter,
  .table-responsive .dataTables_info {
    text-align: center;
    width: 100%;
  }

  .table-responsive>.row>div[class^="col-"] {
    padding: 0px;
  }

  .filter_boxs {
    flex-wrap: wrap;
  }

  .dataTables_filter_select {
    margin-right: 0px;
    width: 100%;
    text-align: center;
  }

  .question-box .action-checkbox {
    justify-content: flex-end;
  }

  .question-row .dataTables_length {
    width: 300px;
  }

  .question-box td.choices {
    width: 100%;
  }

  pagination-template ul.ngx-pagination {
    justify-content: center;
  }

  .question-row .title-box .question-title .question-no {
    width: 55px;
  }
  .courses-bg {
    width: 90px;
    height: 90px;
    padding: 0px;
}
.table-responsive .table tbody td {
  padding-left: 140px;
}
.table-responsive .table tbody td .description-text {
  padding-left: 0px;
}
.table-responsive .table tbody td {
  word-break: break-all;
}
}


@media (max-width:479px) {
  // .description-td .description-text {
  //   padding-left: 85px;
  // }

  .note-editable.card-block {
    height: 150px !important;
  }

  .Scrollable-form {
    max-height: 60vh;
  }

  .assessment_name {
    max-width: 100%;
  }

  .draft_published {
    float: none;
    display: flex;
    justify-content: space-between;
  }

  .question-row .dataTables_length {
    width: 100%;
    margin-bottom: 7px;
    justify-content: flex-end;
  }

  .question-row .title-box {
    flex-wrap: wrap;
  }

  .question-row .title-box .question-title {
    order: 2;
    position: relative;
    padding-left: 40px;
    width: 100% !important;
  }

  .question-no {
    position: absolute;
    left: 0px;
    top: 0px;
  }

  .assessment_name .form-control {
    width: 100%;
  }

  .angular-editor-wrapper .angular-editor-textarea {
    height: 150px !important;
    outline: none !important;
  }

  .question-row .title-box .question-title .question-no {
    width: 30px;
  }
}

/**** responsive ****/
// @media (max-width:1280px){
// 	.question-row .title-box .question-title .question-no {
// 		width: 55px;
// 	}

// }

// @media (max-width: 992px){
// 	.custom_file {margin-bottom: 1rem!important;}

// }
// @media (max-width:769px){
// 	.table-responsive .table thead {display: none;}
// 	.table-responsive .table tbody td {display: block;text-align: end;position: relative;padding-left: 0px;padding-right: 0px;}
// 	.table-responsive .table tbody td .user-icon {float: none;}
// 	.table-responsive .table tbody td:before {content: attr(data-title)":";position: absolute;left: 5px;color: #505d69;font-weight: 600;font-family: Nunito,sans-serif;}
// 	.table-responsive .table tbody td[data-title="Labels"] {height: 40px;}
// 	.table-responsive .table tbody td:first-child {border-top: 0px;}
// 	.description-td .description-text {margin-left: auto;}
// 	.table-responsive .table tbody td:last-child {border-bottom: 1px solid #c9cbce;}
// 	.table-responsive .table tbody tr:last-child td:last-child {border-bottom: 0px;}
// 	.dataTables_length label, .dataTables_filter,.table-responsive .dataTables_info {text-align: center;width: 100%;}
// 	.table-responsive>.row>div[class^="col-"] {padding: 0px;}
// 	.filter_boxs {flex-wrap: wrap;}
// 	.dataTables_filter_select {margin-right: 0px;width: 100%;text-align: center;}
// 	.question-box .action-checkbox {justify-content: flex-end;}
// 	.question-row .dataTables_length {
// 		width:400px;
// 	}
// 	.question-box td.choices {
// 		width: 100%;
// 	}
// 	.question-row .title-box .question-title {
// 		width: 100%;
//   }
//   .table-responsive .table tbody td {
//     padding-left: 95px;
// }

// .table-responsive .table tbody td .description-text {
//     padding-left: 0px;
// }
// .table-responsive .table tbody td[data-title="Correct Answer"] {
//     min-height: 44px;
// }
// }


// @media (max-width:479px){
// 	.description-td .description-text {padding-left: 85px;}
// 	.note-editable.card-block {height: 150px !important;}
// 	.Scrollable-form {max-height: 60vh;}
// 	.assessment_name {max-width: 100%;}
// 	.draft_published {float: none;display: flex;justify-content: space-between;}

// 	.question-row .dataTables_length {
// 		width: 100%;
// 		margin-bottom: 7px;
// 		justify-content: flex-end;
// 	}

// 	.question-row .title-box {
// 		flex-wrap: wrap;
// 	}

// 	.question-row .title-box .question-title {
// 		order: 2;     position: relative;
// 		padding-left: 40px;
// 	}
// 	.question-no {
// 		position: absolute;
// 		left: 0px;
// 		top: 0px;
// 	}
// 	.assessment_name .form-control {
// 		width: 100%;
// 	}
// 	.nav-tabs-custom .nav-item .nav-link {
// 		padding-left: 5px;
// 		padding-right: 5px;
// 		font-size: 12px;
//   }

// }


// Angular Editor
button#insertVideo- {
  display: none;
}

.angular-editor-toolbar-set {
  display: none;
}

.button#strikeThrough- {
  display: none;
}

button#unlink- {
  display: none;
}

button#insertHorizontalRule- {
  display: none;
}

button#strikeThrough- {
  display: none;
}

button#subscript- {
  display: none;
}

button#superscript- {
  display: none;
}

button#clearFormatting- {
  display: none;
}

button#insertImage- {
  display: none;
}